










































































































































































































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import { BSpinner, BTable, BvTableField } from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/fundraising/components/InvestorsInnerTable.vue";
import { CheckboxOption } from '@/store/types';
import { BACKEND_BASE_URL, periodTypeOptions, periodTypes } from "@/shared/consts";
import Utils from "@/modules/shared/utils/utils";
import { Watch } from 'vue-property-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { TableQuery } from '@/modules/shared/utils/TableQuery';
import SygniPagination from '@/components/table/SygniPagination.vue';
import SygniFlagPicker from '@/components/inputs/SygniFlagPicker.vue';
import SygniRadio from '@/components/inputs/SygniRadio.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import Vue from 'vue';
import { TemplateActions, TemplateStatuses } from '@/modules/genprox/modules/fund/modules/fundraising/modules/templates/store/types';
import ClatTaxModal from '@/components/ClatTaxModal.vue';
import { Statues } from '@/shared/interfaces/Statues';
import { create, all } from 'mathjs'
const math = create(all);
import _ from 'lodash';

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniLoader, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, SygniCheckbox, SygniTextArea, SygniSquareButton, SygniRectButton, SygniModal, SygniFlagPicker, SygniRadio, SygniInput, SygniDatePicker, ClatTaxModal
  },
})
export default class ClatTaxTable extends SygniTable<any> {
  TemplateStatuses: any = TemplateStatuses;
  TemplateActions: any = TemplateActions;
  isPaymentStatusLoading: boolean = false;
  isSepa: boolean = false;
  isAccountingDateLoading: boolean = false;
  isModalLoading: boolean = false;
  bulkRows: Array<CheckboxOption> = [];
  selectAllRowsBoolean: boolean | null = false;
  showBankAccountModal: boolean = false;
  bulkOptionsMarginTop: number = 0;
  isClatTaxPaymentPackageLoading: boolean = false;
  useCustomPaymentDate: boolean = false;
  customPaymentDate: string = '';
  customPackageName: string = '';
  paymentForSingleDocument: any = false;
  bulkPaymentStatus: string = '';
  bulkPaymentStatusPlaceholder: string = 'Select option';
  accountingDateOpened: boolean = false;
  isCsvExporting: boolean = false;
  accountingDate: string = '';
  detailsModalId: string = '';
  refresh: any = null;
  productIds: string[] = []
  clatTaxPreview: boolean = false;
  useCustomClatTaxPaymentDate: boolean = false;
  clatTaxPackageModal: any = {
    customPackageName: '',
    customPaymentDate: '',
    taxOffice: null,
    bankAccount: null,
    period: {
      type: 'month',
      value: null,
      year: null,
    }
  };
  bankAccounts: any[] = [];

  tableFields: (BvTableField & { borderless?: Boolean } & { key: string })[] = [
    { key: 'productCode', sortable: true, borderless: true, class: 'text-left', label: 'Product Code' },
    { key: 'investor', class: 'text-left', sortable: true, borderless: true, label: 'Investor' },
    { key: 'productType', sortable: true, borderless: true, class: 'text-center', label: 'Product Type' },
    { key: 'paymentDeadline', class: 'text-center', sortable: true, borderless: true, label: 'Payment Deadline' },
    { key: 'statementGenerated', class: 'text-center', sortable: true, borderless: false, label: 'Generated' },
    { key: 'value', class: 'text-right', sortable: true, borderless: false, label: 'Value' },
    { key: 'paymentDate', class: 'text-center', sortable: true, borderless: true, label: 'Payment Date' },
    { key: 'paymentStatus', class: 'text-center', sortable: true, borderless: false, label: 'Payment Status' },
    { key: 'actions', sortable: false, label: 'Actions' },
  ];

  tableItems: any = [
    {
      id: 1,
      productCode: null,
      investmentClient: 'Tomasz Bryliński',
      productType: 'LOAN',
      paymentDeadline: '2023-04-30T00:00:00+00:00',
      value: 150000.00,
      currency: 'PLN',
      paymentDate: '2023-04-26T00:00:00+00:00',
      paymentStatus: 'paid',
    }
  ]

  clatTaxModalOpened: boolean = false

  refreshClatTaxPackageModalData() {
    this.useCustomClatTaxPaymentDate = false
    this.clatTaxPackageModal.customPackageName = ''
    this.clatTaxPackageModal.customPaymentDate = ''
    this.clatTaxPackageModal.taxOffice = null
    this.clatTaxPackageModal.period.type = 'month'
    this.clatTaxPackageModal.period.value = null
    this.clatTaxPackageModal.period.year = null

    if (this.bankAccountOptions?.length === 1) {
      this.clatTaxPackageModal.bankAccount = this.bankAccountOptions[0]?.value || null
    } else {
      this.clatTaxPackageModal.bankAccount = null
    }
  }

  get periodTypes() {
    return periodTypes
  }

  get periodTypeOptions() {
    const options = periodTypeOptions

    return this.clatTaxPackageModal?.period?.type ? options[this.clatTaxPackageModal?.period?.type] : []
  }

  get isPeriodDay() {
    return this.clatTaxPackageModal?.period?.type === 'day'
  }

  get isPeriodYear() {
    return this.clatTaxPackageModal?.period?.type === 'year'
  }

  get periodValueLabel() {
    switch (this.clatTaxPackageModal?.period?.type?.toLowerCase()) {
      case "semiannual":
        return 'HALF YEAR'
      default:
        return this.clatTaxPackageModal?.period?.type?.toUpperCase()
    }
  }

  get bankAccountOptions() {
    return this.bankAccounts?.filter((el: any) => el.forClatTax && el?.target?.toLowerCase() === 'marketplace')?.map((el: any) => {
      return { label: el?.account, value: el?.account }
    })
  }

  get selectedBankAccount() {
    if (this.clatTaxPackageModal?.bankAccount) {
      return this.bankAccounts?.find((el: any) => el.account === this.clatTaxPackageModal?.bankAccount)
    }

    return null
  }

  get taxOfficeCodes() {
    const options = this.$store.getters['investors/taxOfficeCodes']

    return options.map((el: any) => {
      return { label: `${el.value?.toString()} - ${el.label}`, value: el.value }
    })
  }

  get hasOnlyValidDeclarationOptions() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    let onlyValid = true;

    if (selectedRowsIds.length) {
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        if (item && !this.isClatTaxGenerationEnabled(item)) {
          onlyValid = false;
        }
      });

      return onlyValid;
    }

    return false;
  }

  get totals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if (currency) {
        if (totals[currency]) {
          totals[currency] = item.value ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.value))) : math.number(math.add(math.bignumber(totals[currency]), 0));
        } else {
          totals[currency] = item.value ? math.bignumber(math.bignumber(item.value)) : 0;
        }
      }
    })

    return totals;
  }

  closeClatTaxModal() {
    this.clatTaxModalOpened = false;
    this.clatTaxPreview = false
    this.productIds = []
    this.refreshTable()
  }

  loadGeneratedDeclarations(item: any) {
    if (item?.statementGenerated) {
      this.clatTaxPreview = true
      this.openClatTaxModal('single', item)
    }
  }

  openClatTaxModal(type: 'single' | 'bulk', item: any) {
    if (type === 'single') {
      if (this.isClatTaxGenerationEnabled(item)) {
        this.productIds = [item?.productId]
        this.clatTaxModalOpened = true
      }
    } else if (type === 'bulk') {
      const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label).map((id: string) => {
        const item = this.items.find((el: any) => el.id === id)
        return item.productId
      });

      this.productIds = selectedRowsIds
      this.clatTaxModalOpened = true
    }
  }

  isClatTaxGenerationEnabled(item: any) {
    return !!(item?.productCode && item?.productType?.toUpperCase() === 'LOAN')
  }

  clatTaxGenerationTooltipText(item: any) {
    return (this.isClatTaxGenerationEnabled(item)) ? 'Generate declation' : 'Declaration can be generated only for LOAN products that contains agreement date and product code'
  }
  
  get tableFieldsComputed() {
    const fields = this.tableFields

    if (this.isPaymentManager || this.isMarketplaceAdmin || this.isMarketplaceHead || this.isMarketplaceAccountant) {
      fields.unshift({ key: 'selected', sortable: false, borderless: true, label: '' })
    }

    return fields
  }

  async enableBankAccountModal(item?: any) {
    this.paymentForSingleDocument = (item?.id) ? item.id : false;
    this.showBankAccountModal = true;
    this.isClatTaxPaymentPackageLoading = true
    this.$v?.$reset()
    
    try {
      if (!this.bankAccounts?.length) {
        const bankAccounts = await this.$store.dispatch('genprox/getContextMarketplaceWalletNumber')
        this.bankAccounts = bankAccounts
      }
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.refreshClatTaxPackageModalData()
    this.isClatTaxPaymentPackageLoading = false
  }

  closeBankAccountModal() {
    this.$v.$reset();
    this.showBankAccountModal = false;
  }
  
  get isMarketplaceAdmin() {
    return this.activeUserData?.role?.marketplace === 'admin' ? true : false;
  }

  get isMarketplaceHead() {
    return this.activeUserData?.role?.marketplace === 'head' ? true : false;
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  getTooltipStatusMessage(item: any) {
    if (item.status == 'rejected') return item?.note ? item.note : '';

    return item.status == 'failed' ? item.errorMessage : '';
  }

  closeRejectionModal() {
    this.showRejectionModal = false;
    this.rejectionNote = '';
  }

  enableRejectionModal() {
    this.showRejectionModal = true;
  }

  getRowDataActionItems(items: Array<string>) {
    const totalItems = [...items, 'download'];

    return totalItems;
  }

  statusClass(status: string) {
    switch (status) {
      case ('unpaid'): return 'danger';
      case ('paid'): return 'primary';
      case ('scheduled'): return 'success';
      case ('cancelled'): return 'danger';
      case ('partially paid'): return 'lead';
      case ('annex'): return 'lead';
    }

    return 'black';
  }

  setPaymentStatus(value: any) {
    this.openedRow.paymentStatus = value;
  }

  setPaymentDate(value: any) {
    this.openedRow.paymentDate = value ? value : null;
  }

  setDescription(value: any) {
    this.openedRow.description = value ? value : null;
  }

  get paymentStatuses() {
    return this.paymentDictionaries?.paymentStatus ? this.paymentDictionaries?.paymentStatus?.map((el: any) => {
      return { label: this.$options.filters.capitalizeFirstLetter(el.label), value: el.value }
    }) : []
  }

  get paymentDictionaries() {
    return this.$store.getters['products/getDictionary'] ? this.$store.getters['products/getDictionary'] : {};
  }

  get parentLink() {
    return (this.$route.path.includes('company')) ? 'company' : 'fund';
  }

  get actionItems() {
    let actions: Array<string> = [];

    this.items.forEach((item: any) => {
      actions.push(...item.actions);
    });

    actions = actions.filter((action: string) => {
      return !(action == 'edit-payment-status' || action == 'preview' || action == 'approve' || action == 'reject');
    });

    actions = [...new Set(actions), 'download'];

    if (actions.indexOf('delete') != -1) {
      actions.push(actions.splice(actions.indexOf('delete'), 1)[0]);
    }

    return actions;
  }

  get formattedActionItems() {
    return this.actionItems.map((value: string, index: number) => {
      return {
        id: index + 1,
        key: value,
      }
    })
  }

  get actionItemsLength() {
    return this.actionItems.length;
  }

  get defaultPaymentPackageName() {
    const date = new Date();
    return `Payment package from ${date.getFullYear()}-${Utils.pad(date.getMonth() + 1, 2)}-${Utils.pad(date.getDate() + 1, 2)} ${Utils.pad(date.getHours(), 2)}:${Utils.pad(date.getMinutes(), 2)}:${Utils.pad(date.getSeconds(), 2)}`;
  }

  async exportCsv() {
    this.isCsvExporting = true;

    try {
      let csvContent = await this.$store.dispatch('products/exportCsvByIds', { ids: this.rowsSelected.map((el: any) => el.label) });
      csvContent = csvContent.replace(/[\r]+/g, '').replace(';', ' ').replace(/&nbsp;/g, '').replace(/"[^"]*(?:""[^"]*)*"/g, function (m: any) { return m.replace(/\n/g, ''); })
      const universalBOM: string = '\uFEFF';
      let data = `data:text/csv;charset=utf-8,${encodeURIComponent(universalBOM + csvContent)}`;

      const link = document.createElement('a');
      link.setAttribute('href', data)
      link.setAttribute('download', `export.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch(error) {
      const errorMessage = this.$options.filters.errorHandler(error);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }

    this.isCsvExporting = false;
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('products/setClatTaxTableFiltersQuery', filtersQuery);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('products/setClatTaxTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit(this.$attrs.sortingQueryAction, this.sortingQuery);
      this.getItems();
    });
  }

  openDetailsModal(id: string) {
    this.detailsModalId = id;
  }

  closeDetailsModal() {
    this.detailsModalId = null;
    this.refreshTable();
  }

  get openedRow() {
    return this.items?.find((el: any) => el.id === this.detailsModalId) ? this.items?.find((el: any) => el.id === this.detailsModalId) : null;
  }

  openAccountingDateModal() {
    this.accountingDateOpened = true;
  }

  closeAccountingDateModal() {
    this.accountingDateOpened = false;
  }

  refreshTable(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$nextTick(() => {
      this.getItems();
    })
  }

  toggleAllRowsAction() {
    if (this.selectAllRowsBoolean === null) return;
    this.selectAllRowsBoolean = !this.selectAllRowsBoolean;

    if (this.selectAllRowsBoolean) {
      const selectedRowEl = (this.$refs.paymentsTable as BTable).$el.querySelector('.table tbody tr:nth-of-type(1)');
      this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement)?.offsetTop + (selectedRowEl as HTMLDivElement)?.offsetHeight - 20;
      (this.$refs.paymentsTable as BTable).selectAllRows();
    } else {
      (this.$refs.paymentsTable as BTable).clearSelected();
    }

    this.bulkRows.forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  selectRowEl(index: number) {
    if (this.$refs.paymentsTable == undefined) return;

    this.deselectRowEl();
    const activeRow = ((this.$refs.paymentsTable as ClatTaxTable).$el as HTMLElement).querySelector(`tbody tr[aria-rowindex="${index + 1}"]`);
    this.$scrollTo(activeRow);
    activeRow.classList.add('active');
  }

  deselectRowEl() {
    if (this.$refs.paymentsTable == undefined) return;

    const rows = ((this.$refs.paymentsTable as ClatTaxTable).$el as HTMLElement).querySelectorAll(`tbody tr`);
    rows.forEach(row => row.classList.remove('active'));
  }

  get paymentsTableQuery() {
    return this.$store.getters['products/getClatTaxTableQuery'];
  }

  toggleTableRow(item: any, index: number) {
    const selectedRowEl = (this.$refs.paymentsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(${index + 1})`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement)?.offsetTop + (selectedRowEl as HTMLDivElement)?.offsetHeight - 20;
    if (this.bulkRows[index]) {
      this.bulkRows[index].value = !this.bulkRows[index].value;
    }

    if (item.value) {
      (this.$refs.paymentsTable as BTable).selectRow(index);
    } else {
      (this.$refs.paymentsTable as BTable).unselectRow(index);
    }
  }

  clearTableSelection() {
    this.selectAllRowsBoolean = false;
    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = false;
    });
  }

  downloadDocument() {
    if (this.activeDocument == undefined) return;
    this.downloadFileByUrl(this.activeDocument);
  }

  setQuery() {
    this.$store.commit('products/setClatTaxTableQuery', this.localTableQuery);
  }

  async getItems() {
    await this.$store.dispatch(this.$attrs.getItemsAction, '');
    this.resetCheckboxes();
  }

  resetCheckboxes(): void {
    this.bulkRows = [];
    this.items.forEach((el: any, index: any) => {
      this.$set(this.bulkRows, index, { label: el.id, value: false });
    });
  }

  downloadFileByUrl(file: any) {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.pdfFilePath}`, file.fileName);
  }

  downloadMultipleDocuments() {
    const documentUrls = this.rowsSelected.map(el => {
      const item = this.items.find((item: any) => el.label == item.id);
      return { fileName: item.fileName, path: item.pdfFilePath };
    });
    Utils.downloadZippedFilesByUrls(documentUrls);
  }

  deleteRow(item: any) {
    this.$emit('deleteRow', item);
  }

  get filtersQuery() {
    return this.$store.getters['products/getClatTaxTableFiltersQuery'];
  }

  get rowsSelected() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows;
  }

  get paymentPackageItems() {
    let items: any = this.rowsSelected.map((el: any) => {
      return this.items.find((item: any) => item.id === el.label) ? this.items.find((item: any) => item.id === el.label) : el;
    });

    items = _.groupBy(items, 'productId')
    const results: any = [];

    Object.keys(items).forEach((item: any) => {
      results.push(item)
    })

    return results;
  }

  get selectedRowsLength() {
    const selectedRows = this.bulkRows?.filter(el => el.value);

    return selectedRows?.length;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isPaymentManager() {
    return this.activeUserData.role?.paymentManager ? true : false;
  }

  get validationObject() {
    if (this.showBankAccountModal) {
      return {
        clatTaxPackageModal: {
          customPaymentDate: { required: requiredIf(() => this.useCustomClatTaxPaymentDate) },
          taxOffice: { required },
          bankAccount: { required },
          // period: {
          //   type: { required },
          //   value: { required: requiredIf(() => !(this.isPeriodDay || this.isPeriodYear)) },
          //   year: { required },
          // }
        }
      }
    }

    return {}
  }

  validations() {
    return this.validationObject
  }

  buildPeriodPayload() {
    let periodDate = null
    switch (this.clatTaxPackageModal?.period?.type) {
      case 'day':
        periodDate = this.clatTaxPackageModal?.period?.year
        break;
      default:
        periodDate = this.clatTaxPackageModal?.period?.value ? this.clatTaxPackageModal?.period?.value?.replace('XXXX', this.clatTaxPackageModal?.period?.year) : 'XXXX-01-01 00:00:00'?.replace('XXXX', this.clatTaxPackageModal?.period?.year)
        break;
    }

    const payload: any = {
      periodType: this.clatTaxPackageModal?.period?.type?.toLowerCase(),
      periodDate
    }

    return payload
  }

  async generatePaymentPackage() {
    let items = this.paymentPackageItems;

    if (this.paymentForSingleDocument) {
      const item = this.items.find((el: any) => el.id === this.paymentForSingleDocument)
      items = [item.productId]
    }

    this.$v.$touch();

    if (!this.$v.$error) {
      this.isClatTaxPaymentPackageLoading = true;
      
      if (items.length) {
        try {
          const bankAccount: any = this.bankAccounts?.find((el: any) => el?.account === this.clatTaxPackageModal?.bankAccount)
          const bankAccountType: string = bankAccount ? bankAccount?.type : 'mBank'

          const payload: any = {
            name: this.clatTaxPackageModal?.customPackageName ? this.clatTaxPackageModal.customPackageName : this.defaultPaymentPackageName,
            paymentDate: this.useCustomClatTaxPaymentDate ? `${this.clatTaxPackageModal?.customPaymentDate} 00:00:00` : null,
            bankAccount: this.clatTaxPackageModal?.bankAccount,
            bankAccountType,
            taxOfficeCode: this.clatTaxPackageModal?.taxOffice,
            items,
            filters: this.filtersQueryString,
            // ...periodData
          }
          await this.$store.dispatch('products/generateClatTaxPaymentPackage', payload);
          this.closeBankAccountModal();
          this.onFiltersChange(this.filtersQuery);
        } catch (error) {
          const errorMessage = this.$options.filters.errorHandler(error);
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: this.$t(errorMessage).toString()
          });
        }
      }

      this.isClatTaxPaymentPackageLoading = false;
    }
  }

  async beforeMount() {
    await this.getItems();
    this.onMounted();
    this.setBorderlessTds();
  }

  async goToProductSummary(item: any) {
    this.$store.commit('investors/clearAnnexingData');
    if (item.productStatus === Statues.pending) {
      await this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/accept-by-legal-entity/${item.productId}` });
    } else {
      await this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/summary/${item.productId}/for-legal-entity` });
    }
  }

  goToProfile(item: any): void {
    if(item.investmentClientId) {
      this.$router.push({name: 'profile-dashboard-guest-investor', params: { id: item.investmentClientId}})
    }
  }

  addPagination(): void {
    const ComponentClass = Vue.extend(SygniPagination);
    this.paginationInstance = new ComponentClass<SygniPagination>();

    const currentPage = this.paymentsTableQuery.offset / this.paymentsTableQuery.limit + 1;

    this.paginationInstance.showPageNumberOptions = this.showPageNumberOptions;
    this.paginationInstance.perPage = this.tableData.perPage;
    this.paginationInstance.totalRows = this.tableData.totalCount;
    this.paginationInstance.currentPage = currentPage;
    this.paginationInstance.$mount();
    this.paginationInstance.$on('change', (currentPage: number) => {
      this.$store.commit(this.setTableBusyMutation, true);
      this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      this.localTableQuery.limit = this.tableData.perPage;
      this.localTableQuery.offset = this.tableData.perPage * (currentPage - 1);
      this.setQuery();
      this.getItems();
      this.$scrollTo(((this.$refs.paymentsTable as ClatTaxTable).$el as HTMLElement));
    });
    this.paginationInstance.$on('changePerPageOption', (perPage: number) => {
      if (this.tablePerPageMutation) this.$store.commit(this.tablePerPageMutation, perPage);
      this.$store.commit(this.setTableBusyMutation, true);
      this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      this.localTableQuery.limit = this.tableData.perPage;
      this.localTableQuery.offset = 0;
      this.setQuery();
      this.getItems();
      this.changePerPageOption();
    });

    this.$nextTick(() => {
      this.paginationInstance.$emit('changePage', currentPage)
    })

    const tableElement: Element = this.$el.querySelector('.sygni-b-table') as Element;

    const isClatTaxTable = this.$el.classList.contains('clat-tax-table');
    const isWhistleblowerTable = this.$el.classList.contains('whistleblower-table');
    const isPortfolioTable = this.$el.classList.contains('portfolio-table');

    if (isClatTaxTable) {
      this.$el.querySelector('.clat-tax-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if (isWhistleblowerTable) {
      this.$el.querySelector('.whistleblower-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if (isPortfolioTable) {
      this.$el.querySelector('.portfolio-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else {
      this.$el.insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    }
  }

  async paymentStatusBulkUpdate() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);

    const ids = selectedRowsIds.map((id: string) => {
      const item = this.items.find((el: any) => el.id === id);
      return item.productId;
    });

    const payload = { productIds: ids, status: this.bulkPaymentStatus };
    this.isPaymentStatusLoading = true;

    try {
      await this.$store.dispatch('products/updateClatTaxPaymentStatuses', payload);
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        item.paymentStatus = payload.status;
      });
      this.isPaymentStatusLoading = false;
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Payment statuses updated.'
      });
    } catch (err) {
      const errorMessage = this.$options.filters.errorHandler(err);
      this.isPaymentStatusLoading = false;
      this.$notify({
        duration: 3000,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
  }

  async accountingDateBulkUpdate() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);

    const productIds = selectedRowsIds.map((id: string) => {
      const item = this.items.find((el: any) => el.id === id);
      return item.productId;
    });

    const payload = { productIds, paymentDate: this.accountingDate };

    this.isAccountingDateLoading = true;

    try {
      await this.$store.dispatch('products/updateClatTaxPaymentDates', payload);
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        item.paymentDate = payload.paymentDate;
      });
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Payment dates updated.'
      });
    } catch (err) {
      const errorMessage = this.$options.filters.errorHandler(err);
      this.$notify({
        duration: 3000,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isAccountingDateLoading = false;
  }

  async updatePaymentData() {
    this.isModalLoading = true;
    try {
      const productId = this.openedRow.productId;
      const data = {
        paymentStatus: this.openedRow.paymentStatus,
        paymentDate: this.openedRow.paymentDate,
        description: this.openedRow?.description || null,
      };

      const payload = { productId, data };
      await this.$store.dispatch('products/updateClatTaxPaymentData', payload)

      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Payment details updated.'
      });
      this.closeDetailsModal();

    } catch (err) {
      const errorMessage = this.$options.filters.errorHandler(err);
      this.$notify({
        duration: 3000,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    this.isModalLoading = false;
  }

  @Watch('bulkPaymentStatus') onBulkPaymentStatusChange(): void {
    if (this.bulkPaymentStatus) {
      this.paymentStatusBulkUpdate();
    }
  }

  @Watch('accountingDate') onAccountingDateChange() {
    if (this.accountingDate) {
      this.accountingDateBulkUpdate();
    }
  }

  @Watch('selectAllRowsBoolean') onSelectAllRowsBooleanChange(): void {
    const selectedRowEl = (this.$refs.paymentsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(1)`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement)?.offsetTop + (selectedRowEl as HTMLDivElement)?.offsetHeight - 20;

    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  @Watch('clatTaxPackageModal.period.type', { immediate: true }) onClatTaxPaymentPackageModalPeriodTypeChange() {
    this.clatTaxPackageModal.period.value = null
    this.$v?.clatTaxPackageModal?.period?.value?.$reset()
  }

  @Watch('items') onItemsChange(): void {
    if (this.refresh) {
      clearTimeout(this.refresh);
      this.refresh = undefined;
    }

    if (this.isProcessing) {
      this.$store.commit('products/setClatTaxTableBusy', true);
      this.refresh = setTimeout(() => {
        this.onFiltersChange(this.filtersQuery);
      }, 3000);
    }
  }
}

