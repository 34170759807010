































import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import { BSpinner } from 'bootstrap-vue';
import LandingPage from './LandingPage.vue';
import { Campaign } from '../modules/campaigns/store/types';

@Component({
  components: { LandingPage, BSpinner },
})
export default class LandingPageModal extends Vue {
  @Prop({ default: null }) campaignData: Campaign;
  @Prop() isLoading!: boolean;
  @Prop() show!: boolean;

  closeModal() {
    this.$emit('close');
  }
}
