












































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {Statues} from "@/shared/interfaces/Statues";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniInnerTable from "@/components/table/SygniInnerTable.vue";
import { Product } from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import { BTable } from 'bootstrap-vue'
import { Prop } from 'vue-property-decorator';

@Component({
  components: { SygniRectButton, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, SygniCheckbox }
})
export default class SubscriptionsInnerTable extends SygniInnerTable<Product> {
  @Prop({ default: null }) subscription: any;

  bulkOptionsMarginTop: number = 0
  selectedProducts: string[] = []
  selectAllProductsOption: boolean = false

  innerTableFields = [
    {key: 'code', sortable: false, class: ['code text-left']},
    {key: 'investorName', sortable: false, class: 'investor-name'},
    {key: 'sourceName', sortable: false, class: ['source-name']},
    {key: 'seriesOfSharesName', sortable: false, class: ['series-of-shares'], label: 'Series of shares' },
    {key: 'instrumentCount', sortable: false, class: ['instrument-count']},
    {key: 'counterFrom', sortable: false, class: ['counter-from']},
    {key: 'counterTo', sortable: false, class: ['counter-to']},
    {key: 'investmentValue', sortable: false, class: ['investment-value', 'text-right']},
  ]

  innerTableFieldsLlc = [
    { key: 'code', sortable: false, class: ['code text-left'] },
    { key: 'investorName', sortable: false, class: 'investor-name' },
    { key: 'sourceName', sortable: false, class: ['source-name'] },
    // { key: 'seriesOfShares', sortable: false, class: ['series-of-shares'] },
    { key: 'instrumentCount', sortable: false, class: ['instrument-count'] },
    // { key: 'counterFrom', sortable: false, class: ['counter-from'] },
    // { key: 'counterTo', sortable: false, class: ['counter-to'] },
    { key: 'investmentValue', sortable: false, class: ['investment-value', 'text-right'] },
  ]

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get isLlc() {
    return this.activeUserData?.equityType !== 'JOINT_STOCK_SHARES'
  }

  get innerTableFieldsComputed() {
    if (this.isLlc) {
      return this.innerTableFieldsLlc
    }

    return this.innerTableFields
  }

  goToInvestorProfile(investorId: string): void {
    if (investorId) {
      this.$router.push({ name: 'profile-dashboard-guest-investor', params: { id: investorId } })
    }
  }

  goToSourceProfile(sourceId: string): void {
    if (sourceId) {
      this.$router.push({ name: 'profile-dashboard-guest', params: { id: sourceId } });
    }
  }

  getTooltipMessage(message: string) {
    return message?.length <= 35 ? '' : message
  }

  clearTableSelection() {
    this.selectedProducts = []
  }

  selectAllProducts() {
    this.$nextTick(() => {
      if(this.selectAllProductsOption) {
        const allProductIds = this.items.filter((el: any) => this.canBeAnnexed(el)).map((el: any) => el.id)
        
        this.selectedProducts = allProductIds
        
        const index = this.items.findIndex((el: any) => this.canBeAnnexed(el))
        const selectedRowEl = (this.$refs.innerTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(${index + 1})`);
        this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
      } else {
        this.clearTableSelection()
      }
    })
  }

  selectProduct(id: string, rowData: any) {
    this.$nextTick(() => {
      if (this.selectedProducts.includes(id)) {
        const index = this.selectedProducts.findIndex((el: string) => el === id)
        this.selectedProducts.splice(index, 1)
      } else {
        this.selectedProducts.push(id)
      }

      const selectedRowEl = (this.$refs.innerTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(${rowData.index + 1})`);
      this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
    })
  }

  isSelected(id: string) {
    return this.selectedProducts.includes(id) ? true : false
  }

  hasError(id: string) {
    return this.subscription?.errors?.find((el: any) => el?.productId === id)
  }

  getErrorMessage(id: string) {
    const errors = this.subscription?.errors?.filter((el: any) => el?.productId === id)

    return errors?.map((el: any) => el.errorMsg)?.join(', ') || ''
  }

  statusClass(status: Statues) {
    switch(status.toUpperCase()) {
      case(Statues.new): {
        return 'black';
      }
      
      case(Statues.active): {
        return 'primary';
      }
      case(Statues.invited): {
        return 'success';
      }
      case(Statues.awaiting): {
        return 'success';
      }
      case(Statues.pending): {
        return 'black';
      }
      case(Statues.rejected): {
        return 'danger';
      }
      case(Statues.declined): {
        return 'disabled';
      }
    }
    return '';
  }

  statusText(status: Statues): string {
    switch(status.toUpperCase()) {
      case(Statues.new): {
        return 'New';
      }
      case(Statues.active): {
        return 'Active';
      }
      case(Statues.invited): {
        return 'Awaiting';
      }
      case(Statues.awaiting): {
        return 'Awaiting';
      }
      case(Statues.pending): {
        return 'Pending';
      }
      case(Statues.rejected): {
        return 'Rejected';
      }
      case(Statues.declined): {
        return 'Declined';
      }
    }
    return '';
  }


  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  mounted() {
    this.onMounted();
  }

  async goToProductSummary(product: Product){
    this.$store.commit('investors/clearAnnexingData');
    if(product.status === Statues.pending){
      await this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/accept-by-legal-entity/${product.id}` });
    } else {
      await this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/summary/${product.id}/for-legal-entity` });
    }
  }
}

