var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "products-table"
  }, [_c('div', {
    staticClass: "products-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    staticClass: "sygni-b-inner-table sygni-b-table",
    attrs: {
      "id": "fe_products_grid",
      "innertable": "true",
      "tbody-class": "fe_products_grid_tbody",
      "fields": _vm.computedTableFields,
      "show-empty": true
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(code)",
      fn: function fn(rowData) {
        return [_c('a', {
          staticClass: "code",
          attrs: {
            "href": _vm.getProductSummaryLink(rowData.item)
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.goToProductSummary(rowData.item);
            }
          }
        }, [_vm._v(" " + _vm._s(rowData.item.code ? rowData.item.code : 'NONE') + " ")])];
      }
    }, {
      key: "cell(investmentClientName)",
      fn: function fn(rowData) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.getTooltipMessage(rowData.item.investmentClientName),
            expression: "getTooltipMessage(rowData.item.investmentClientName)",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "investor-name text-nowrap",
          on: {
            "click": function click($event) {
              return _vm.goToProfile(rowData.item);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("trimString")(rowData.item.investmentClientName, 35)) + " ")])];
      }
    }, {
      key: "cell(sourceName)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "source-name"
        }, [_vm._v(" " + _vm._s(rowData.item.sourceName) + " ")])];
      }
    }, {
      key: "cell(ownerName)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "owner-name text-left"
        }, [_vm._v(" " + _vm._s(rowData.item.ownerName) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(rowData) {
        return [rowData.item.type.toUpperCase() === 'LLC_SHARES' ? [_vm._v(" LLC Shares ")] : _vm._e(), rowData.item.type.toUpperCase() === 'JOINT_STOCK_SHARES' ? [_vm._v(" Joint-stock Shares ")] : _vm._e(), rowData.item.type.toUpperCase() !== 'LLC_SHARES' && rowData.item.type.toUpperCase() !== 'JOINT_STOCK_SHARES' ? [_vm._v(" " + _vm._s(_vm._f("snakeCaseToTitleCase")(rowData.item.type)) + " ")] : _vm._e()];
      }
    }, {
      key: "cell(agreementDate)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("dateWithoutTime")(rowData.item.agreementDate)) + " ")];
      }
    }, {
      key: "cell(autentiStatus)",
      fn: function fn(rowData) {
        var _rowData$item, _rowData$item2, _rowData$item3;

        return [rowData !== null && rowData !== void 0 && (_rowData$item = rowData.item) !== null && _rowData$item !== void 0 && _rowData$item.autentiStatus || rowData !== null && rowData !== void 0 && (_rowData$item2 = rowData.item) !== null && _rowData$item2 !== void 0 && _rowData$item2.eSigned ? _c('div', {
          staticClass: "status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: rowData === null || rowData === void 0 ? void 0 : (_rowData$item3 = rowData.item) === null || _rowData$item3 === void 0 ? void 0 : _rowData$item3.autentiErrorMessage,
            expression: "rowData?.item?.autentiErrorMessage",
            modifiers: {
              "hover": true
            }
          }],
          class: "outline round ".concat(_vm.autentiStatusClass(rowData.item)),
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(" " + _vm._s(_vm.autentiStatusText(rowData.item)) + " ")])], 1) : _vm._e()];
      }
    }, {
      key: "cell(repaymentScheduleGenerated)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm.getRepaymentScheduleGeneratedTitle(rowData.item.repaymentScheduleGenerated)) + " ")];
      }
    }, {
      key: "cell(signedStatus)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: "outline round ".concat(_vm.signedStatusClass(rowData.item.signedStatus)),
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(" " + _vm._s(_vm.signedStatusText(rowData.item.signedStatus)) + " ")])], 1)];
      }
    }, {
      key: "cell(status)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: "outline round ".concat(_vm.statusClass(rowData.item.status)),
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(" " + _vm._s(_vm.statusText(rowData.item.status)) + " ")])], 1)];
      }
    }, {
      key: "cell(value)",
      fn: function fn(rowData) {
        return [rowData.item.value.value ? _c('span', [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.value.value, 2)) + " " + _vm._s(rowData.item.value.currency) + " ")]) : _vm._e()];
      }
    }, {
      key: "custom-foot",
      fn: function fn(data) {
        return [_c('tr', _vm._l(data.columns, function (i) {
          var _data$fields, _data$fields2, _data$fields3;

          return _c('td', {
            key: i,
            class: "td-".concat(data.fields[i - 1].key)
          }, [((_data$fields = data.fields[i - 1]) === null || _data$fields === void 0 ? void 0 : _data$fields.key) == 'code' ? _c('div', [_c('div', {
            staticClass: "totals totals--align-left"
          }, [_c('div', {
            staticClass: "totals__left"
          }, [_c('p', [_c('strong', [_vm._v("Total products:")])])]), _c('div', {
            staticClass: "totals__right"
          }, [_c('p', [_vm._v(_vm._s(_vm.items.length ? _vm.items.length : 0))])])])]) : _vm._e(), ((_data$fields2 = data.fields[i - 1]) === null || _data$fields2 === void 0 ? void 0 : _data$fields2.key) == 'status' ? _c('div', {
            staticClass: "text-center"
          }, [_c('p', [_c('strong', [_vm._v("Total amount:")])])]) : _vm._e(), ((_data$fields3 = data.fields[i - 1]) === null || _data$fields3 === void 0 ? void 0 : _data$fields3.key) == 'value' ? _c('div', {
            staticClass: "text-right"
          }, [_c('div', {
            staticClass: "totals"
          }, [_c('div', {
            staticClass: "totals__left"
          }, _vm._l(Object.entries(_vm.totals), function (_ref) {
            var key = _ref[0],
                value = _ref[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)) + " " + _vm._s(_vm.index))]);
          }), 0), _c('div', {
            staticClass: "totals__right"
          }, _vm._l(Object.entries(_vm.totals), function (_ref2) {
            var key = _ref2[0];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(key))]);
          }), 0)])]) : _vm._e()]);
        }), 0)];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }