var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subscriptions-inner-table"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "innerTable",
    staticClass: "sygni-b-inner-table",
    attrs: {
      "innertable": "true",
      "show-empty": true,
      "fields": _vm.innerTableFieldsComputed
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "head(selected)",
      fn: function fn() {
        return [_c('div', {
          staticClass: "table-checkbox"
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "label": ""
          },
          on: {
            "input": _vm.selectAllProducts
          },
          model: {
            value: _vm.selectAllProductsOption,
            callback: function callback($$v) {
              _vm.selectAllProductsOption = $$v;
            },
            expression: "selectAllProductsOption"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "cell(selected)",
      fn: function fn(rowData) {
        return [_vm.canBeAnnexed(rowData.item) ? _c('div', {
          staticClass: "table-checkbox",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.selectProduct(rowData.item.id, rowData);
            }
          }
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "label": "",
            "value": _vm.isSelected(rowData.item.id)
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(code)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-left"
        }, [_c('span', {
          staticClass: "text-nowrap code",
          on: {
            "click": function click($event) {
              return _vm.goToProductSummary(rowData.item);
            }
          }
        }, [_c('span', {
          staticClass: "hoverable"
        }, [_vm._v(_vm._s(rowData.item.code ? rowData.item.code : 'NONE'))]), _vm.hasError(rowData.item.id) ? _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              variant: 'danger',
              boundary: 'viewport'
            },
            expression: "{ variant: 'danger', boundary: 'viewport' }",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "code__info",
          attrs: {
            "title": _vm.getErrorMessage(rowData.item.id)
          }
        }, [_vm._v("i")]) : _vm._e()])])];
      }
    }, {
      key: "cell(agreementDate)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "transaction-date text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("dateWithoutTime")(rowData.item.agreementDate)) + " ")])];
      }
    }, {
      key: "cell(investorName)",
      fn: function fn(rowData) {
        var _rowData$item;

        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.getTooltipMessage(rowData.item.investorName),
            expression: "getTooltipMessage(rowData.item.investorName)",
            modifiers: {
              "hover": true
            }
          }],
          class: ['investor-name text-nowrap', rowData !== null && rowData !== void 0 && (_rowData$item = rowData.item) !== null && _rowData$item !== void 0 && _rowData$item.investmentClientId ? 'hoverable' : ''],
          on: {
            "click": function click($event) {
              var _rowData$item2;

              return _vm.goToInvestorProfile(rowData === null || rowData === void 0 ? void 0 : (_rowData$item2 = rowData.item) === null || _rowData$item2 === void 0 ? void 0 : _rowData$item2.investmentClientId);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("trimString")(rowData.item.investorName, 35)) + " ")])];
      }
    }, {
      key: "cell(sourceName)",
      fn: function fn(rowData) {
        var _rowData$item3;

        return [_c('div', {
          class: ['instrument-type', rowData !== null && rowData !== void 0 && (_rowData$item3 = rowData.item) !== null && _rowData$item3 !== void 0 && _rowData$item3.sourceId ? 'hoverable' : ''],
          on: {
            "click": function click($event) {
              var _rowData$item4;

              return _vm.goToSourceProfile(rowData === null || rowData === void 0 ? void 0 : (_rowData$item4 = rowData.item) === null || _rowData$item4 === void 0 ? void 0 : _rowData$item4.sourceId);
            }
          }
        }, [_vm._v(" " + _vm._s(rowData.item.sourceName) + " ")])];
      }
    }, {
      key: "cell(investmentValue)",
      fn: function fn(rowData) {
        var _rowData$item$investm, _rowData$item$investm2;

        return [_c('div', {
          staticClass: "investor-name text-right"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item$investm = rowData.item.investmentValue) === null || _rowData$item$investm === void 0 ? void 0 : _rowData$item$investm.value, 2)) + " " + _vm._s((_rowData$item$investm2 = rowData.item.investmentValue) === null || _rowData$item$investm2 === void 0 ? void 0 : _rowData$item$investm2.currency) + " ")])];
      }
    }, {
      key: "cell(instrumentCount)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "investor-name"
        }, [_vm._v(" " + _vm._s(_vm._f("thousandSeparator")(rowData.item.instrumentCount)) + " ")])];
      }
    }, {
      key: "cell(counterFrom)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "investor-name"
        }, [_vm._v(" " + _vm._s(_vm._f("thousandSeparator")(rowData.item.counterFrom)) + " ")])];
      }
    }, {
      key: "cell(counterTo)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "investor-name"
        }, [_vm._v(" " + _vm._s(_vm._f("thousandSeparator")(rowData.item.counterTo)) + " ")])];
      }
    }, {
      key: "cell(instrumentClass)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "instrument-class"
        }, [_vm._v(" " + _vm._s(rowData.item.instrumentClass) + " ")])];
      }
    }, {
      key: "cell(seriesOfSharesName)",
      fn: function fn(rowData) {
        var _rowData$item5;

        return [_c('div', {
          staticClass: "series-of-shares"
        }, [_c('span', {
          staticClass: "share"
        }, [_vm._v(" " + _vm._s((_rowData$item5 = rowData.item) === null || _rowData$item5 === void 0 ? void 0 : _rowData$item5.seriesOfSharesName) + " ")])])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: "outline round ".concat(_vm.statusClass(rowData.item.status)),
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(" " + _vm._s(_vm.statusText(rowData.item.status)) + " ")])], 1)];
      }
    }, {
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "cell cell--empty"
        }, [_vm._v("No products yet")])];
      },
      proxy: true
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }