import { render, staticRenderFns } from "./SygniTableActions.vue?vue&type=template&id=53c98a79&scoped=true&"
import script from "./SygniTableActions.vue?vue&type=script&lang=ts&"
export * from "./SygniTableActions.vue?vue&type=script&lang=ts&"
import style0 from "./SygniTableActions.vue?vue&type=style&index=0&id=53c98a79&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c98a79",
  null
  
)

export default component.exports