var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['lp-modal', _vm.show ? 'lp-modal--visible' : '']
  }, [_c('div', {
    staticClass: "lp-modal__bg",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "lp-modal__box"
  }, [_c('div', {
    class: ['lp-modal__loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]), _c('div', {
    staticClass: "lp-modal__actions"
  }, [_c('button', {
    staticClass: "lp-modal__action lp-modal__action--close",
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "lp-modal__inner"
  }, [_c('div', {
    staticClass: "lp-modal__body"
  }, [_c('landing-page', {
    ref: "landingPage",
    attrs: {
      "campaignData": _vm.campaignData
    }
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }