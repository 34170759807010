


































































































































import Component, { mixins } from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniRangeInput from "@/components/inputs/SygniRangeInput.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniButtonsGroup from "@/components/layout/SygniButtonsGroup.vue";
import {SelectOption} from "@/store/types";
import {Filter, FilterFunctions, FilterMixin, FilterOperators} from "@/shared/mixins/FilterMixin";
import {AddProductSelects, InvestorSelects} from "@/modules/shared/dictionary-store/types";
import {mapGetters } from "vuex";
import {Statues} from "@/shared/interfaces/Statues";
import SygniCheckableButton from "@/components/buttons/SygniCheckableButton.vue";

@Component({
  components: {
    SygniCheckableButton,
    SygniRangeInput,
    SygniButtonsGroup,
    SygniDatePicker, SygniContainerTitle, SygniRoundedButton, SygniSquareButton, SygniSelect, SygniInput
  },
  computed: {
    ...mapGetters('dictionary', {
      investorSelects: 'investorsSelects',
      productSelects: 'addProductsSelects'
    })
  }
})
export default class ProductFilters extends mixins (FilterMixin) {
  investors: SelectOption[] = [{value:'Adam', label: 'Adam'}, {value: 'Marek', label: 'Marek'}];
  investorSelects!: InvestorSelects;
  productSelects!: AddProductSelects;
  readonly Statues = Statues;

  itemsUrl = 'marketplace/legal-entity/products';

  filters: Record<string, Filter> = {
    investmentClientName: { value: null, getQueryValue: FilterFunctions.string , operator: FilterOperators.like, type: 'string' },
    investmentClientInvestmentPotential: { value: null, getQueryValue: FilterFunctions.string , operator: FilterOperators.eq, type: 'string' },
    investmentClientSource: { value: null, getQueryValue: FilterFunctions.source , operator: FilterOperators.eq, type: 'string' },
    investmentClientStatus: { value: [], getQueryValue: FilterFunctions.array , operator: FilterOperators.in, type: 'string' },
    productType: { value: null, getQueryValue: FilterFunctions.string , operator: FilterOperators.eq, type: 'string' },
    productCode: { value: null, getQueryValue: FilterFunctions.string , operator: FilterOperators.like, type: 'string' },
    productValue: { value: {from: null, to: null}, getQueryValue: FilterFunctions.range , operator: FilterOperators.like, type: 'range' },
    productAgreementDate: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    productSource: { value: null, getQueryValue: FilterFunctions.source , operator: FilterOperators.eq, type: 'string' },
    productClass: { value: null, getQueryValue: FilterFunctions.string , operator: FilterOperators.like, type: 'string' },
    productStatus: { value: [], getQueryValue: FilterFunctions.array , operator: FilterOperators.in, type: 'string' },
  };

  applyFilters(): void {
    if(this.isLoading) return;
    this.$emit('filtersChange', this.getFilterQuery());
  }

  getProductsQuery(): string {
    let filterQueryTable: Array<any> = this.filterQuery.split('&');
    for(let i = 1; i < filterQueryTable.length; i++){
      if((filterQueryTable[i] as string).includes('investment_client')){
        filterQueryTable.splice(i,1);
        i--;
      }
    }
    let query = filterQueryTable.join('&');
    if(query[0] === '&'){
      query = '?' + query.slice(1,query.length);
    } else{
      query = '?' + query;
    }
    return query
  }

  createCustomQuery(filter: Filter, filterName: string): string {
    let query: string = '';
    let queryValue = filter.getQueryValue(filter.value);
    switch(filterName){
      case('investmentClientSource'): {
        if(this.filters.investmentClientSource.value === 'MARKETPLACE' ||
            this.filters.investmentClientSource.value === 'FUNDEQUATE' ){
          queryValue = filter.getQueryValue(filter.value);
          query += `&filters[investmentClientSourceType][0][value]=${queryValue}`;
          query += `&filters[investmentClientSourceType][0][operator]=${filter.operator}`;
        } else {
          queryValue = filter.getQueryValue(filter.value);
          query += `&filters[investmentClientSourceId][0][value]=${queryValue}`;
          query += `&filters[investmentClientSourceId][0][operator]=${filter.operator}`;
        }
        break;
      }
      case('productSource'): {
        if(this.filters.investmentClientSource.value === 'MARKETPLACE' ||
            this.filters.investmentClientSource.value === 'FUNDEQUATE' ){
          queryValue = filter.getQueryValue(filter.value);
          query += `&filters[productSourceType][0][value]=${queryValue}`;
          query += `&filters[productSourceType][0][operator]=${filter.operator}`;
        } else {
          queryValue = filter.getQueryValue(filter.value);
          query += `&filters[productSourceId][0][value]=${queryValue}`;
          query += `&filters[productSourceId][0][operator]=${filter.operator}`;
        }
      }
    }
    return query;
  }

  mounted(){
    this.onMounted();
  }

  clearAll() {
    for(let key in this.filters){
      if(Array.isArray(this.filters[key].value)){
        this.filters[key].value = [];
      } else if(this.filters[key].value?.to){
        this.filters[key].value.to = '';
        this.filters[key].value.from = '';
      } else {
        this.filters[key].value = '';
      }
    }
    this.applyFilters();
  }
}
