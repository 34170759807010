var render = function () {
  var _vm$flag, _vm$flag2, _vm$owner, _vm$owner2, _vm$owner3, _vm$flag3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flag-access-modal"
  }, [_c('div', {
    staticClass: "flag-access-modal__bg",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }), _c('div', {
    staticClass: "flag-access-modal__box"
  }, [_c('div', {
    class: ['flag-access-modal__loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]), _c('div', {
    staticClass: "flag-access-modal__actions"
  }, [_c('h2', {
    staticClass: "flag-access-modal__header"
  }, [_vm._v("Share")]), _c('button', {
    staticClass: "flag-access-modal__action flag-access-modal__action--close",
    on: {
      "click": function click($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "flag-access-modal__inner"
  }, [_c('div', {
    staticClass: "flag-access-modal__body"
  }, [_c('div', {
    staticClass: "flag-access-modal__options"
  }, [_c('div', {
    staticClass: "flag-access-modal__option"
  }, [_c('sygni-flag', {
    attrs: {
      "color": (_vm$flag = _vm.flag) === null || _vm$flag === void 0 ? void 0 : _vm$flag.color
    }
  }), _c('p', {
    staticClass: "flag-access-modal__option-text"
  }, [_vm._v(_vm._s((_vm$flag2 = _vm.flag) === null || _vm$flag2 === void 0 ? void 0 : _vm$flag2.name))]), _c('div', {
    staticClass: "flag-access-modal__option-btn",
    on: {
      "click": function click($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v("Change")])], 1)])])]), _c('div', {
    staticClass: "flag-access-modal__footer"
  }, [_c('div', {
    staticClass: "flag-access-modal__footer-inner"
  }, [_c('div', {
    staticClass: "flag-access-modal__footer-input"
  }, [_c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.employeeOptions,
      "searchable": true,
      "label": "ADD PERSON"
    },
    on: {
      "input": _vm.shareTagWithPerson
    },
    model: {
      value: _vm.newUser,
      callback: function callback($$v) {
        _vm.newUser = $$v;
      },
      expression: "newUser"
    }
  })], 1)]), _c('p', {
    staticClass: "flag-access-modal__header"
  }, [_vm._v("People with access")]), _c('div', {
    staticClass: "flag-access-modal__footer-inner"
  }, [_c('div', {
    staticClass: "flag-access-modal__people"
  }, [_vm.owner ? _c('div', {
    staticClass: "flag-access-modal__person"
  }, [_c('user-presentation', {
    attrs: {
      "name": (_vm$owner = _vm.owner) === null || _vm$owner === void 0 ? void 0 : _vm$owner.name,
      "description": (_vm$owner2 = _vm.owner) === null || _vm$owner2 === void 0 ? void 0 : _vm$owner2.email,
      "image": (_vm$owner3 = _vm.owner) === null || _vm$owner3 === void 0 ? void 0 : _vm$owner3.logo
    }
  }), _c('div', {
    staticClass: "flag-access-modal__person-role"
  }, [_vm._v("Owner")])], 1) : _vm._e(), _vm._l((_vm$flag3 = _vm.flag) === null || _vm$flag3 === void 0 ? void 0 : _vm$flag3.sharedWith, function (user) {
    return _c('div', {
      key: user,
      staticClass: "flag-access-modal__person"
    }, [_c('user-presentation', {
      attrs: {
        "name": user.naturalPersonName,
        "description": user === null || user === void 0 ? void 0 : user.naturalPersonEmail,
        "image": user === null || user === void 0 ? void 0 : user.naturalPersonPhoto
      }
    }), _c('div', {
      staticClass: "flag-access-modal__person-role danger"
    }, [_c('sygni-link-button', {
      attrs: {
        "type": "simple"
      },
      on: {
        "click": function click($event) {
          return _vm.removeSharedPerson(user);
        }
      }
    }, [_vm._v("Delete")])], 1)], 1);
  })], 2)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }