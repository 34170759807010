















import Component from 'vue-class-component'
import Vue from "vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import { Prop } from 'vue-property-decorator';

@Component({
  components: {SygniRoundedButton}})
export default class EmptyInvestorsTable extends Vue{
  @Prop() manageInvestorsEnabled: boolean;

  goToInvestorForm() {
    if(this.$route.path.includes('company')) {
      this.$router.push('/company/fundraising/new-investor/form/step-one');
    } else {
      this.$router.push('/fund/fundraising/new-investor/form/step-one');
    }
  }
}

