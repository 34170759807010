var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "register"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [!_vm.isRegistrationCompleted ? _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "content"
  }, [_vm.showLiveDemo ? _c('sygni-selectable-title', {
    attrs: {
      "options": [{
        label: 'Register',
        value: 'register'
      }, {
        label: 'Live demo',
        value: 'demo'
      }]
    },
    model: {
      value: _vm.formTypeInputData,
      callback: function callback($$v) {
        _vm.formTypeInputData = $$v;
      },
      expression: "formTypeInputData"
    }
  }) : _vm._e(), !_vm.showLiveDemo ? _c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header__text red"
  }, [_vm._v(" Register ")]), _c('div', {
    staticClass: "header__text grey"
  })]) : _vm._e(), _vm.formType === 'register' ? _c('register-form', {
    on: {
      "registerCompleted": function registerCompleted($event) {
        _vm.isRegistrationCompleted = true;
      }
    }
  }) : _vm._e(), _vm.formType === 'demo' ? _c('live-demo-form') : _vm._e()], 1)]) : _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', {
    attrs: {
      "line-height": 4
    }
  }, [_vm._v("Register")])], 1), _vm._m(0)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "register-completed"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/accept.svg")
    }
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v("Registration completed")]), _c('div', {
    staticClass: "description"
  }, [_vm._v("Please activate your account by email")])]);
}]

export { render, staticRenderFns }