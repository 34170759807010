








































































import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniFileBox from '@/components/layout/SygniFileBox.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import SygniColorPicker from '@/components/inputs/SygniColorPicker.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import { requiredIf } from 'vuelidate/lib/validators';
import { BSpinner } from 'bootstrap-vue';

export interface Network {
  name: string,
}

Component.registerHooks(['validations'])
@Component({
  components: { SygniLinkButton, SygniRoundedButton, SygniFileBox, SygniRectButton, SygniInput, SygniSelect, SygniLoader, SygniModal, SygniColorPicker, SygniCheckbox, BSpinner },
})
export default class NetworksManagementModal extends Vue {
  @Prop() isLoading!: boolean;
  @Prop({ default: null }) showedBrokerId: string | null;
  @Prop({ default: null }) brokerData: any;
  
  editedNetworkId: string = '';

  newNetwork: Network = {
    name: '',
  }

  editedNetwork: Network = {
    name: '',
  }

  toggleFocusOnNewNetworkInput() {
    ((this.$refs.newNetworkInput as any).$refs.sygniInputEl as HTMLInputElement).focus();
  }

  clearNewNetwork() {
    this.newNetwork.name = '';
    this.$v.$reset();
  }

  get networks() {
    return this.$store.getters['genprox/networks']
  }

  get activeUserDataId() {
    return this.$store.getters['genprox/activeUserData']?.user?.id;
  }

  isCoordinator(id: string) {
    return this.brokerData?.coordinatorIds?.includes(id) || false
  }

  showShareIcon(flag: any) {
    if(flag.type == 'owner' && flag.sharedWith.length) return true;

    if(flag.type == 'shared') return true;

    return false;
  }

  actionsEnabled(network: any) {
    return this.activeUserDataId === network.ownerId;
  }

  openEditMode(network: any) {
    this.editedNetworkId = network.id;
    this.editedNetwork.name = network.name;
  }

  openShareMode(network: any) {
    this.$emit('showNetworkAccessModal', network);
  }

  closeEditMode() {
    this.editedNetworkId = '';
    this.editedNetwork.name = '';
    this.$v.$reset();
  }

  async refreshNetworks(): Promise<void> {
    await this.$store.dispatch('genprox/getNetworks');
    this.isLoading = false;
    this.$emit('change')
  }

  async toggleCoordinator(network: any) {
    this.isLoading = true;
    try {
      await this.$store.dispatch('advisors/updateAdvisorInNetwork', {
        id: network.id,
        brokerId: this.showedBrokerId,
        coordinator: !(this.isCoordinator(network.id))
      });
      await this.refreshNetworks();
      this.closeEditMode();

    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.isLoading = false;
      this.$notify({
        duration: 5000,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      })
    }
  }

  async addNewNetwork(): Promise<void> {
    this.$v.$touch();

    if(!this.$v.$error) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('genprox/postNetwork', this.newNetwork);
        await this.refreshNetworks();
        this.clearNewNetwork();
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.isLoading = false;
        this.$notify({
          duration: 5000,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        })
      }
    }
  }

  async putNewNetwork(): Promise<void> {
    this.$v.$touch();

    if(!this.$v.$error) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('genprox/putNetwork', {
          id: this.editedNetworkId,
          name: this.editedNetwork.name
        });
        await this.refreshNetworks();
        this.closeEditMode();
      } catch(e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.isLoading = false;
        this.$notify({
          duration: 5000,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        })
      }
    }
  }

  async deleteNetwork(id: string): Promise<void> {
    this.isLoading = true;
    try {
      await this.$store.dispatch('genprox/deleteNetwork', id);
      await this.refreshNetworks();
      this.isLoading = false;
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.isLoading = false;
      this.$notify({
        duration: 5000,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      })
    }
  }

  validations() {
    return {
      newNetwork: {
        name: { required: requiredIf(() => !this.editedNetworkId) },
      },
      editedNetwork: {
        name: { required: requiredIf(() => this.editedNetworkId) },
      },
    }
  }
}
