






























































import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniFileBox from '@/components/layout/SygniFileBox.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import SygniColorPicker from '@/components/inputs/SygniColorPicker.vue';
import { FlagObject } from '@/modules/genprox/store/types';
import { BSpinner } from 'bootstrap-vue';
import SygniFlag from '@/components/layout/SygniFlag.vue';
import UserPresentation from '@/components/UserPresentation.vue';

@Component({
  components: { SygniLinkButton, SygniRoundedButton, SygniFileBox, SygniRectButton, SygniInput, SygniSelect, SygniLoader, SygniModal, SygniColorPicker, BSpinner, SygniFlag, UserPresentation },
})
export default class FlagAccessModal extends Vue {
  @Prop({default: null}) flag: any;

  isLoading: boolean = false;
  newUser: any = null;

  get flags(): Array<FlagObject> {
    return this.$store.getters['genprox/flags']
  }

  get employees() {
    return this.$store.getters['genprox/employees'];
  }

  get employeeOptions() {
    const employees = this.employees.filter((el: any) => {
      return this.peopleIds.includes(el.naturalPersonId) ? false : true;
    });

    return employees.map((el: any) => {
      return { label: el.name, value: el.naturalPersonId }
    })
  }

  get owner() {
    return this.employees.find((el: any) => el.naturalPersonId === this.flag?.ownerId);
  }

  get peopleIds() {
    const sharedPeopleIds = this.flag?.sharedWith?.map((el: any) => {
      return el.naturalPersonId;
    })

    return [this.flag?.ownerId].concat(sharedPeopleIds);
  }

  async removeSharedPerson(user: any) {
    this.isLoading = true;
    try {
      await this.$store.dispatch('genprox/removeSharedFlag', user.id);
      const personIndex = this.flag.sharedWith.findIndex((el: any) => el.id == user.id);
      this.flag.sharedWith.splice(personIndex, 1);
      this.isLoading = false;
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.isLoading = false;
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      })
      this.isLoading = false;
    }
  }

  async shareTagWithPerson(id: string) {
    if(id) {
      this.isLoading = true;
      try {
        const flagId = await this.$store.dispatch('genprox/shareFlag', {
          userId: id,
          flagId: this.flag.id,
        })
  
        const person = this.employees.find((el: any) => el.naturalPersonId == id);
        this.flag.sharedWith.push({
          id: flagId,
          naturalPersonId: person.naturalPersonId,
          naturalPersonName: person.name,
          naturalPersonPhoto: person.logo,
        });
        this.isLoading = false;
        this.newUser = '';
      } catch(e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.isLoading = false;
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        });
        this.isLoading = false;
      } 
    }
  }

  closeModal() {
    this.$emit('close');
    this.newUser = null;
  }

  async mounted() {
    if(!this.employees.length) {
      await this.$store.dispatch('genprox/getEmployees');
    }
  }
}
