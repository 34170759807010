var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['agreements-table', _vm.isMarketplaceAccountant ? 'agreements-table--preview' : '']
  }, [_c('div', {
    staticClass: "agreements-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "agreementsTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.computedTableFields,
      "sort-by": _vm.sortBy,
      "show-empty": true,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "per-page": _vm.perPage,
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "row-clicked": _vm.onRowClicked,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "head(selected)",
      fn: function fn() {
        return [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.selectAllRowsBoolean,
            callback: function callback($$v) {
              _vm.selectAllRowsBoolean = $$v;
            },
            expression: "selectAllRowsBoolean"
          }
        })];
      },
      proxy: true
    }, _vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(selected)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table-checkbox",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.toggleTableRow(_vm.bulkRows[rowData === null || rowData === void 0 ? void 0 : rowData.index], rowData === null || rowData === void 0 ? void 0 : rowData.index);
            }
          }
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.bulkRows[rowData.index].value,
            callback: function callback($$v) {
              _vm.$set(_vm.bulkRows[rowData.index], "value", $$v);
            },
            expression: "bulkRows[rowData.index].value"
          }
        })], 1)];
      }
    }, {
      key: "cell(isAttachment)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("snakeCaseToTitleCase")(rowData.item.isAttachment ? 'ATTACHMENT' : 'TEMPLATE')) + " ")];
      }
    }, {
      key: "cell(author)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(rowData.item.author))])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.createdAt)))])];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.updatedAt)))])];
      }
    }, {
      key: "cell(autentiEnabled)",
      fn: function fn(rowData) {
        var _rowData$item;

        return [_c('div', {
          staticClass: "text-center"
        }, [rowData !== null && rowData !== void 0 && (_rowData$item = rowData.item) !== null && _rowData$item !== void 0 && _rowData$item.autentiEnabled ? _c('img', {
          attrs: {
            "src": _vm._f("getIcon")('ACCEPT'),
            "alt": ""
          }
        }) : _vm._e()])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: "filled round ".concat(_vm.statusClass(rowData.item.status)),
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(" " + _vm._s(rowData.item.status) + " ")])], 1)];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "td__actions",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('div', {
          staticClass: "td__actions-elements"
        }, [!_vm.isMarketplaceAccountant ? [_c('div', {
          staticClass: "action",
          on: {
            "click": function click($event) {
              return _vm.$router.push("/".concat(_vm.parentLink, "/templates/").concat(rowData.item.id));
            }
          }
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Edit',
            expression: "'Edit'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('EDIT')
          }
        })])]), rowData.item.status !== _vm.TemplateStatuses.ACTIVE && rowData.item.status !== _vm.TemplateStatuses.ARCHIVED ? _c('div', {
          staticClass: "action",
          on: {
            "click": function click($event) {
              return _vm.setTemplateStatus(rowData.item.id, _vm.TemplateActions.ACTIVE);
            }
          }
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Mark as active',
            expression: "'Mark as active'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('APPROVE')
          }
        })])]) : _vm._e(), rowData.item.status !== _vm.TemplateStatuses.DRAFT && rowData.item.status !== _vm.TemplateStatuses.ARCHIVED ? _c('div', {
          staticClass: "action",
          on: {
            "click": function click($event) {
              return _vm.setTemplateStatus(rowData.item.id, _vm.TemplateActions.DRAFT);
            }
          }
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Mark as draft',
            expression: "'Mark as draft'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('PADLOCK')
          }
        })])]) : _vm._e(), rowData.item.status !== _vm.TemplateStatuses.ARCHIVED ? _c('div', {
          staticClass: "action action--delete",
          on: {
            "click": function click($event) {
              return _vm.setTemplateStatus(rowData.item.id, _vm.TemplateActions.ARCHIVED);
            }
          }
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Archive',
            expression: "'Archive'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action__el action__el--delete"
        }, [_c('svg', {
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "15.605",
            "height": "18",
            "viewBox": "0 0 15.605 18"
          }
        }, [_c('path', {
          staticStyle: {
            "fill": "currentColor"
          },
          attrs: {
            "d": "M8.188.529a1.8 1.8 0 0 0-1.273.528l-.672.672H3.389a1.807 1.807 0 0 0-1.351.576 1.829 1.829 0 0 0-.453 1.224 1.83 1.83 0 0 0 .453 1.225 1.717 1.717 0 0 0 .749.48v1.895a.6.6 0 1 0 1.2 0V5.33h10.8v11.4a.586.586 0 0 1-.6.6h-9.6a.586.586 0 0 1-.6-.6v-6a.6.6 0 1 0-1.2 0v6a1.809 1.809 0 0 0 1.8 1.8h9.6a1.809 1.809 0 0 0 1.8-1.8V5.235a1.717 1.717 0 0 0 .75-.481 1.83 1.83 0 0 0 .453-1.225 1.829 1.829 0 0 0-.453-1.224 1.807 1.807 0 0 0-1.351-.576h-2.853l-.673-.672a1.8 1.8 0 0 0-1.272-.528zm0 1.2h2.4a.6.6 0 0 1 .424.176l.848.848a.6.6 0 0 0 .424.176h3.1a.533.533 0 0 1 .455.174.685.685 0 0 1 0 .853.533.533 0 0 1-.455.174h-12a.533.533 0 0 1-.455-.174.685.685 0 0 1 0-.853.533.533 0 0 1 .455-.174h3.1a.6.6 0 0 0 .424-.176l.848-.848a.6.6 0 0 1 .432-.176zM6.978 7.72a.6.6 0 0 0-.591.609v6a.6.6 0 1 0 1.2 0v-6a.6.6 0 0 0-.609-.609zm2.4 0a.6.6 0 0 0-.591.609v6a.6.6 0 0 0 1.2 0v-6a.6.6 0 0 0-.609-.609zm2.4 0a.6.6 0 0 0-.591.609v6a.6.6 0 0 0 1.2 0v-6a.6.6 0 0 0-.609-.609z",
            "transform": "translate(-1.585 -.529)"
          }
        })])])]) : _vm._e()] : [_c('div', {
          staticClass: "action",
          on: {
            "click": function click($event) {
              return _vm.goToPreview(rowData.item);
            }
          }
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Preview',
            expression: "'Preview'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('Fund'),
            "alt": "Preview"
          }
        })])])]], 2), _c('div', {
          staticClass: "td__actions-toggle",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('div', {
          staticClass: "action"
        }, [_c('div', {
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('DOTS')
          }
        })])])])])];
      }
    }, {
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "cell cell--empty"
        }, [_vm._v("There are no templates on your list")])];
      },
      proxy: true
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }