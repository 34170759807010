var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marketplace-table"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "investorsTable",
    staticClass: "sygni-b-table",
    attrs: {
      "id": "fe_investors_grid",
      "fields": _vm.computedTableFields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "tbody-class": "fe_investors_grid_tbody",
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "row-clicked": _vm.rowClickHandler
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(name)",
      fn: function fn(rowData) {
        return [_c('user-presentation', {
          staticClass: "table__user-presentation",
          attrs: {
            "name": rowData.item.name,
            "description": rowData.item.description,
            "image": rowData.item.photo,
            "onNameClick": function onNameClick() {
              return _vm.goToProfile(rowData.item);
            }
          }
        })];
      }
    }, {
      key: "cell(status)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: "filled round ".concat(_vm.statusClass(rowData.item.status)),
          attrs: {
            "hoverable": false
          },
          on: {
            "click": _vm.rowClickHandler
          }
        }, [_vm._v(" " + _vm._s(rowData.item.status) + " ")])], 1)];
      }
    }, {
      key: "cell(signedStatuses)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__product-status d-flex  justify-content-center align-items-center"
        }, _vm._l(_vm.getSignedStatuses(rowData.item.signedStatuses), function (status, index) {
          return _c('sygni-circle-button', {
            key: index,
            attrs: {
              "type": "signedStatus",
              "status": status
            }
          });
        }), 1)];
      }
    }, {
      key: "cell(productStatuses)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__product-status d-flex  justify-content-center align-items-center"
        }, [_vm._l(rowData.item.productStatuses, function (status, index) {
          return _c('sygni-circle-button', {
            key: index,
            attrs: {
              "status": status
            }
          });
        }), rowData.item.productStatuses.length === 0 && _vm.addProductEnabled ? _c('sygni-rounded-button', {
          staticClass: "outline disabled",
          attrs: {
            "hoverable": true,
            "plus-icon": true
          },
          on: {
            "click": function click($event) {
              return _vm.openAddNewProductForm(rowData.item.id);
            }
          }
        }, [_vm._v(" Add new product ")]) : _vm._e()], 2)];
      }
    }, {
      key: "cell(autentiStatuses)",
      fn: function fn(rowData) {
        var _rowData$item;

        return [_c('div', {
          staticClass: "table__product-status d-flex  justify-content-center align-items-center"
        }, _vm._l(_vm.getAutentiStatuses(rowData === null || rowData === void 0 ? void 0 : (_rowData$item = rowData.item) === null || _rowData$item === void 0 ? void 0 : _rowData$item.autentiStatuses), function (status, index) {
          return _c('sygni-circle-button', {
            key: index,
            attrs: {
              "type": "autenti",
              "status": status
            }
          });
        }), 1)];
      }
    }, {
      key: "cell(totalInvestmentValue)",
      fn: function fn(rowData) {
        var _rowData$item2, _rowData$item3;

        return [_c('div', {
          staticClass: "table__arrow d-flex justify-content-end align-items-center pl-4 pr-4",
          attrs: {
            "id": (_rowData$item2 = rowData.item) === null || _rowData$item2 === void 0 ? void 0 : _rowData$item2.id
          }
        }, [_c('span', [_vm.showInvestmentValues(rowData.item) ? _c('span', {
          staticClass: "marketplace-table__tooltip-icon"
        }, [_vm._v("i")]) : _vm._e(), _c('span', {
          staticClass: "marketplace-table__text"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.totalInvestmentValue.value, 2)) + " " + _vm._s(rowData.item.totalInvestmentValue.currency))])]), rowData.item.productStatuses.length > 0 ? _c('div', {
          staticClass: "arrow",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_c('sygni-arrow-button', {
          attrs: {
            "open": rowData.detailsShowing,
            "rotate-on-click": true
          }
        })], 1) : _vm._e(), _vm.showInvestmentValues(rowData.item) ? _c('b-tooltip', {
          attrs: {
            "boundary": "window",
            "placement": "top",
            "target": (_rowData$item3 = rowData.item) === null || _rowData$item3 === void 0 ? void 0 : _rowData$item3.id,
            "triggers": "hover"
          }
        }, [_c('table', {
          staticClass: "values-table"
        }, [_c('tbody', _vm._l(_vm.getInvestmentValues(rowData.item), function (row, index) {
          var _rowData$item4;

          return _c('tr', {
            key: "".concat((_rowData$item4 = rowData.item) === null || _rowData$item4 === void 0 ? void 0 : _rowData$item4.id, "_").concat(row === null || row === void 0 ? void 0 : row.currency, "_").concat(index)
          }, [_c('td', [_vm._v(_vm._s((row === null || row === void 0 ? void 0 : row.currency) || 'PLN') + ":")]), _c('td', [_vm._v(_vm._s(_vm._f("numberFormat")((row === null || row === void 0 ? void 0 : row.value) || 0)))])]);
        }), 0)])]) : _vm._e()], 1)];
      }
    }, {
      key: "cell(sourceName)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__source"
        }, [_vm._v(" " + _vm._s(rowData.item.sourceName))])];
      }
    }, {
      key: "cell(ownerName)",
      fn: function fn(rowData) {
        var _rowData$item5;

        return [_c('div', {
          staticClass: "table__source"
        }, [_c('span', [_vm._v(_vm._s(rowData.item.ownerName))]), !_vm.isMarketplaceAccountant && rowData !== null && rowData !== void 0 && (_rowData$item5 = rowData.item) !== null && _rowData$item5 !== void 0 && _rowData$item5.email ? _c('div', {
          staticClass: "table__source-btn",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.openOwnerModal(rowData.item);
            }
          }
        }, [_vm._v("Change")]) : _vm._e()])];
      }
    }, {
      key: "cell(created)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("dateWithoutTime")(rowData.item.created)) + " ")];
      }
    }, {
      key: "cell(lastLogged)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("dateWithoutTime")(rowData.item.lastLogged)) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        var _rowData$item6;

        return [_c('SygniTableActions', {
          attrs: {
            "id": rowData === null || rowData === void 0 ? void 0 : (_rowData$item6 = rowData.item) === null || _rowData$item6 === void 0 ? void 0 : _rowData$item6.id,
            "itemData": rowData === null || rowData === void 0 ? void 0 : rowData.item,
            "getRowActions": _vm.getRowActions
          }
        })];
      }
    }, {
      key: "row-details",
      fn: function fn(rowData) {
        return [_c('investors-inner-table', {
          attrs: {
            "items": rowData.item.details,
            "investorId": rowData.item.id
          }
        }), _c('div', {
          staticClass: "add-product-container"
        }, [_vm.addProductEnabled ? _c('sygni-rounded-button', {
          staticClass: "outline disabled",
          attrs: {
            "hoverable": true,
            "plus-icon": true
          },
          on: {
            "click": function click($event) {
              return _vm.openAddNewProductForm(rowData.item.id);
            }
          }
        }, [_vm._v(" Add new product ")]) : _vm._e()], 1)];
      }
    }, {
      key: "custom-foot",
      fn: function fn(data) {
        return [_c('tr', _vm._l(data.columns, function (i) {
          var _data$fields, _data$fields2, _data$fields3;

          return _c('td', {
            key: i,
            class: "td-".concat(data.fields[i - 1].key)
          }, [((_data$fields = data.fields[i - 1]) === null || _data$fields === void 0 ? void 0 : _data$fields.key) == 'name' ? _c('div', [_c('div', {
            staticClass: "totals totals--align-left"
          }, [_c('div', {
            staticClass: "totals__left"
          }, [_c('p', [_c('strong', [_vm._v("Total investors:")])])]), _c('div', {
            staticClass: "totals__right"
          }, [_c('p', [_vm._v(_vm._s(_vm.items.length ? _vm.items.length : 0))])])])]) : _vm._e(), ((_data$fields2 = data.fields[i - 1]) === null || _data$fields2 === void 0 ? void 0 : _data$fields2.key) == 'productStatuses' ? _c('div', {
            staticClass: "text-center"
          }, [_c('p', [_c('strong', [_vm._v("Total amount:")])])]) : _vm._e(), ((_data$fields3 = data.fields[i - 1]) === null || _data$fields3 === void 0 ? void 0 : _data$fields3.key) == 'totalInvestmentValue' ? _c('div', {
            staticClass: "text-right"
          }, [_c('div', {
            staticClass: "totals"
          }, [_c('div', {
            staticClass: "totals__left"
          }, _vm._l(Object.entries(_vm.totals), function (_ref) {
            var key = _ref[0],
                value = _ref[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)) + " " + _vm._s(_vm.index))]);
          }), 0), _c('div', {
            staticClass: "totals__right"
          }, _vm._l(Object.entries(_vm.totals), function (_ref2) {
            var key = _ref2[0];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(key))]);
          }), 0)])]) : _vm._e()]);
        }), 0)];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1), !_vm.isMarketplaceAccountant ? _c('owner-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.openedOwner,
      expression: "openedOwner"
    }],
    attrs: {
      "owner": _vm.openedOwner
    },
    on: {
      "change": _vm.refreshTable,
      "close": _vm.closeOwnerModal
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }