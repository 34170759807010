var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "register-form"
  }, [_c('sygni-input', {
    ref: "emailInput",
    staticClass: "secondary",
    attrs: {
      "label": 'EMAIL',
      "validation": _vm.$v.email
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.email.$touch();
      }
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'FIRST NAME',
      "validation": _vm.$v.name
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.name.$touch();
      }
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'SURNAME',
      "validation": _vm.$v.surname
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.surname.$touch();
      }
    },
    model: {
      value: _vm.surname,
      callback: function callback($$v) {
        _vm.surname = $$v;
      },
      expression: "surname"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'PASSWORD',
      "type": "password",
      "validation": _vm.$v.password
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.password.$touch();
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'REPEAT PASSWORD',
      "type": "password",
      "validation": _vm.$v.repeatPassword
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.repeatPassword.$touch();
      }
    },
    model: {
      value: _vm.repeatPassword,
      callback: function callback($$v) {
        _vm.repeatPassword = $$v;
      },
      expression: "repeatPassword"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showRadios,
      expression: "showRadios"
    }],
    staticClass: "radio-group"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("I AM")]), _c('sygni-radio', {
    staticClass: "secondary",
    attrs: {
      "label": 'FUND MANAGER',
      "group": 'investor-type',
      "radioValue": _vm.UserTypes.fundManager
    },
    model: {
      value: _vm.investorType,
      callback: function callback($$v) {
        _vm.investorType = $$v;
      },
      expression: "investorType"
    }
  }), _c('sygni-radio', {
    staticClass: "secondary",
    attrs: {
      "label": 'STARTUP FOUNDER',
      "radioValue": _vm.UserTypes.startupFounder,
      "group": 'investor-type'
    },
    model: {
      value: _vm.investorType,
      callback: function callback($$v) {
        _vm.investorType = $$v;
      },
      expression: "investorType"
    }
  }), _c('sygni-radio', {
    staticClass: "secondary",
    attrs: {
      "label": 'PRIVATE INVESTOR',
      "radioValue": _vm.UserTypes.privateInvestor,
      "group": 'investor-type'
    },
    model: {
      value: _vm.investorType,
      callback: function callback($$v) {
        _vm.investorType = $$v;
      },
      expression: "investorType"
    }
  }), _c('sygni-input-error', {
    attrs: {
      "validation": _vm.$v.investorType
    }
  })], 1), _c('sygni-line', {
    staticClass: "grey"
  }), _c('sygni-checkbox', {
    staticClass: "secondary no-wrap",
    attrs: {
      "clickableLabel": false,
      "validation": _vm.$v.agreement
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.agreement.$touch();
      }
    },
    model: {
      value: _vm.agreement,
      callback: function callback($$v) {
        _vm.agreement = $$v;
      },
      expression: "agreement"
    }
  }, [_c('div', {
    staticClass: "agreement-text"
  }, [_vm._v(" I consent to the processing of my data in accordance with GDPR and declare that I have read the "), _c('span', {
    staticClass: "hoverable-a-element",
    on: {
      "click": _vm.showRegulations
    }
  }, [_vm._v("privacy policy and terms and conditions")])])]), _vm._m(0), _c('sygni-rounded-button', {
    staticClass: "gn-primary filled",
    attrs: {
      "type": "submit",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.register();
      }
    }
  }, [_vm._v(" Register ")]), _c('div', {
    staticClass: "sign-in-text"
  }, [_vm._v(" Have an account? "), _c('router-link', {
    attrs: {
      "to": "login"
    }
  }, [_c('span', {
    staticClass: "sign-in-text--red hoverable-a-element"
  }, [_vm._v(" Sign in")])])], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "security-text"
  }, [_vm._v(" Your connection is protected by SSL "), _c('img', {
    attrs: {
      "src": require("@/assets/icons/padlock.svg")
    }
  })]);
}]

export { render, staticRenderFns }