























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import { BTooltip } from 'bootstrap-vue';
import { SygniTableAction } from '@/modules/genprox/modules/profile/store/types';

@Component({
  components: { BTooltip }
})
export default class SygniTableActions extends Vue {
  @Prop({ default: null }) id: string;
  @Prop({ default: null }) getRowActions: any
  @Prop({ default: null }) itemData: any

  actions: SygniTableAction[] = []

  getActions(item: any): SygniTableAction[] {
    if (this.getRowActions !== undefined) {
      return this.getRowActions(item) || []
    }

    return []
  }
}
