








































































import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniFileBox from '@/components/layout/SygniFileBox.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import SygniColorPicker from '@/components/inputs/SygniColorPicker.vue';
import { requiredIf } from 'vuelidate/lib/validators';
import { Flag, FlagObject } from '@/modules/genprox/store/types';
import { BSpinner } from 'bootstrap-vue';
import SygniFlag from '@/components/layout/SygniFlag.vue';

Component.registerHooks(['validations'])
@Component({
  components: { SygniLinkButton, SygniRoundedButton, SygniFileBox, SygniRectButton, SygniInput, SygniSelect, SygniLoader, SygniModal, SygniColorPicker, BSpinner, SygniFlag },
})
export default class FlagsManagementModal extends Vue {
  @Prop() isLoading!: boolean;
  
  editedFlagId: string = '';

  newFlag: Flag = {
    name: '',
    color: '',
  }

  editedFlag: Flag = {
    name: '',
    color: '',
  }

  setNewFlagColor(color: string) {
    this.newFlag.color = color;
    this.toggleFocusOnNewFlagInput();
    this.$v.$touch();
  }

  toggleFocusOnNewFlagInput() {
    ((this.$refs.newFlagInput as any).$refs.sygniInputEl as HTMLInputElement).focus();
  }

  clearNewFlag() {
    this.newFlag.name = '';
    (this.$refs.newFlagColorPicker as any).clearColor();
    this.$v.$reset();
  }

  get flags(): Array<FlagObject> {
    return this.$store.getters['genprox/flags']
  }

  get activeUserDataId() {
    return this.$store.getters['genprox/activeUserData']?.user?.id;
  }

  showShareIcon(flag: any) {
    if(flag.type == 'owner' && flag.sharedWith.length) return true;

    if(flag.type == 'shared') return true;

    return false;
  }

  actionsEnabled(flag: any) {
    return this.activeUserDataId === flag.ownerId;
  }

  openEditMode(flag: any) {
    this.editedFlagId = flag.id;
    this.editedFlag.name = flag.name;
    (this.$refs.editedFlagColorPicker as any).selectColor(flag.color);
  }

  openShareMode(flag: any) {
    this.$emit('showFlagAccessModal', flag);
  }

  closeEditMode() {
    this.editedFlagId = '';
    this.editedFlag.name = '';
    (this.$refs.editedFlagColorPicker as any).clearColor();
    this.$v.$reset();
  }

  setEditedFlagColor(color: string) {
    this.editedFlag.color = color;
  }

  async refreshFlags(): Promise<void> {
    await this.$store.dispatch('genprox/getFlags');
    this.isLoading = false;
  }

  async addNewFlag(): Promise<void> {
    this.$v.$touch();

    if(!this.$v.$error) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('genprox/postFlag', this.newFlag);
        await this.refreshFlags();
        this.clearNewFlag();
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.isLoading = false;
        this.$notify({
          duration: 5000,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        })
      }
    }
  }

  async putNewFlag(): Promise<void> {
    this.$v.$touch();

    if(!this.$v.$error) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('genprox/putFlag', {
          id: this.editedFlagId,
          flagData: this.editedFlag
        });
        await this.refreshFlags();
        this.closeEditMode();
      } catch(e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.isLoading = false;
        this.$notify({
          duration: 5000,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        })
      }
    }
  }

  async deleteFlag(id: string): Promise<void> {
    this.isLoading = true;
    try {
      await this.$store.dispatch('genprox/deleteFlag', id);
      await this.refreshFlags();
      this.isLoading = false;
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.isLoading = false;
      this.$notify({
        duration: 5000,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      })
    }
  }

  validations() {
    return {
      newFlag: {
        name: { required: requiredIf(() => !this.editedFlagId) },
        color: { required: requiredIf(() => !this.editedFlagId) },
      },
      editedFlag: {
        name: { required: requiredIf(() => this.editedFlagId) },
        color: { required: requiredIf(() => this.editedFlagId) },
      },
    }
  }
}
