



































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniLine from "@/components/layout/SygniLine.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import LoginForm from "@/modules/auth/components/LoginForm.vue";
import LiveDemoForm from "@/modules/auth/components/LiveDemoForm.vue";
import RegisterForm from "@/modules/auth/components/RegisterForm.vue";
import SygniSelectableTitle from "@/components/layout/SygniSelectableTitle.vue";
import {Prop, Watch} from "vue-property-decorator";

@Component({
  components: {
    SygniSelectableTitle,
    RegisterForm,
    LiveDemoForm,
    LoginForm, SygniContainerTitle, SygniCheckbox, SygniLine, SygniRadio, SygniRoundedButton, SygniInput}
})
export default class Register extends Vue {
  isRegistrationCompleted: boolean = false;

  @Prop({default: 'register'}) formType!: 'register' | 'demo';

  formTypeInputData: 'register' | 'demo' = this.formType;
  @Watch('formTypeInputData') redirect(){
    this.$router.push({name: this.formTypeInputData});
  }

  showLiveDemo: boolean = true;

  mounted(){
    if(this.$route.params.userData) {
      this.showLiveDemo = false;
    }
  }
}

