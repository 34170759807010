var render = function () {
  var _vm$getActions;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-table-actions",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    staticClass: "sygni-table-actions__btn",
    attrs: {
      "id": "actionMenu-".concat(_vm.id)
    }
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('DOTS'),
      "alt": "Open actions"
    }
  })]), _c('b-tooltip', {
    attrs: {
      "placement": "bottom",
      "target": "actionMenu-".concat(_vm.id),
      "custom-class": "sygni-table-actions__menu"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [(_vm$getActions = _vm.getActions(_vm.itemData)) !== null && _vm$getActions !== void 0 && _vm$getActions.length ? _c('div', {
    staticClass: "action-menu"
  }, _vm._l(_vm.getActions(_vm.itemData), function (action) {
    return _c('div', {
      key: "action-".concat(action === null || action === void 0 ? void 0 : action.label, "-").concat(_vm.id),
      staticClass: "action-menu-item",
      on: {
        "click": function click($event) {
          action !== null && action !== void 0 && action.callback ? action.callback(_vm.itemData) : false;
        }
      }
    }, [_c('div', {
      staticClass: "action-menu-item__icon"
    }, [_c('img', {
      attrs: {
        "src": action === null || action === void 0 ? void 0 : action.icon,
        "alt": action === null || action === void 0 ? void 0 : action.label
      }
    })]), _c('div', {
      staticClass: "action-menu-item__label"
    }, [_vm._v(_vm._s(action === null || action === void 0 ? void 0 : action.label))])]);
  }), 0) : _c('div', {
    staticClass: "action-menu"
  }, [_c('div', {
    staticClass: "action-menu-item"
  }, [_c('div', {
    staticClass: "action-menu-item__label"
  }, [_vm._v("No actions available")])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }